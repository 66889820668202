@import "variables";
@import "bootstrap";
@import "calendar";
@import "datepicker";

@import "~slick-carousel/slick/slick";
@import "slickCarousel"; //lokales theme

//@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";

//@import "bootstrap-select";
//@import "~bootstrap-select/sass/bootstrap-select";

.pagination {
    flex-wrap: wrap;
}

.table-responsive {
	margin-bottom: 1rem;
	
	& > .table {
		margin-bottom: 0!important;
		
		td:not(:first-child) {
			white-space: nowrap;
		}
	}
}

fieldset {
	padding-bottom: 15px;
}

#anfrage-form {
	&.periodisch {
		[data-terminart="periodisch"] {
			display: block !important;
		}
		[data-terminart="einzeltermin"] {
			display: none !important;
		}
	}
	&:not(.periodisch) {
		[data-terminart="einzeltermin"] {
			display: block !important;
		}
		[data-terminart="periodisch"] {
			display: none !important;
		}
	}
}

.list-group {
	.einrichtung-links {
		float: right; 
		text-align: right;
	}
}


@import "custom";


