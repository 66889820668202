//font-size
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-sm: 0.875rem;

//colors
$black: #000;
$dark: #333;
//$link-color: $dark;
$light: #F7F7F7;
$white: #fff;

//links
$link-hover-decoration: none;

//headings
//$headings-font-family: 'DBSansBold';
$headings-font-weight: bold;
$headings-margin-bottom: 1rem;
//$headings-color: $dark;
$h1-font-size: 45px;
$h2-font-size: 32px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 16px;

$h1-color: rgba(0, 0, 0);
$link-color: #0072A4;

//grid breakpoints and container widths
$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px
);

$input-group-addon-bg: $light;

$form-group-margin-bottom: 20px;

@import "custom";