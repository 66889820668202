$link-color: #0066b4;
$text-color: #000;

@font-face {
    font-family: Source Sans Pro;
    src: url("../fonts/SourceSansPro-Regular.ttf.woff2") format('woff2');
}

@import "~leaflet/dist/leaflet.css";

#map{
	height: 500px;
	max-height: 80vh;
	max-width: 800px;

	&.leaflet-container a {
		color: $text-color;
	}
	.leaflet-popup-content p{
		margin: 10px 0;
	}
}

$font-family-sans-serif: 'Source Sans Pro';
$base-font-size: 1.125rem;


html{
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
html{
    min-height: 100%;
}

body {
    margin: 0;
    font-family: "Source sans Pro", sans-serif;
    font-size:1.375em;
	line-height:1.33333em;
    }

@media only screen and (min-width:40em){
	body{
		font-size: 18px;
		font-size: 1.125rem;
	}
}

a {
    font-weight: 600;
    color: $link-color;
}

p{
    font-weight:500;
}


/* Original h2 Style, wird für h1 verwendet: */
h1 {
	display: block;
	font-size: 2,5em;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: 600;
}


.title {
	margin: 48px 0 16px;
	margin: 3rem 0 1rem;
	//font-size: 28px;
	//font-weight: 500;
}

.title:first-child {
	margin-top: 0;
}

@media only screen and (min-width:40em){
	.article--title{
		font-size: 36px;
	}
}

/* Original h3 Style, für h2 */
//h2,
//h3>a {
//   font-weight: 100;
//   font-family: "Fira Sans", sans-serif;
//   margin: 0;
//   color: #000;
//}

/* Original h4 Style, wird für h3 verwendet:*/
.suchergebnis{
	font-size: $base-font-size;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: bold;
	margin: 0;
	color: #000;


}

.btn {
    font-size: $base-font-size;
}

// Buttons
.btn-primary {
    color: #FFFFFF;
    background-color: $link-color;
    border-color: $link-color;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:not(:disabled):not(.disabled):active, 
.open .dropdown-toggle.btn-primary {
    color: #FFFFFF;
    background-color: lighten($link-color, 7%)!important; //#00AD3F; 
    border-color: $link-color!important;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: $link-color;
    border-color: $link-color;
}

.btn-primary .badge {
    color: $link-color;
    background-color: #FFFFFF;
}

// unset custom font size: 
.custom-select, .form-control, input[class="btn"] {
    font-size: unset!important;
}



.vcard .mail,
.vcard .www,
div.frame-custom-130 a[href^="javascript:linkTo_UnCryptMailto"],
div.frame-custom-130 a[href^="http"] {
    padding: 0 0 0 20px;
}

.vcard .mail,
div.frame-custom-130 a[href^="javascript:linkTo_UnCryptMailto"] {
    background: url("/img/velbert/bg-vcard-icon-mail.png") no-repeat 0 2px;
    background-position-x: 0px;
    background-position-y: 2px;
}

#content div.frame-custom-130 a,
#content div.vcard a {
    font-weight: 100;
    color: $link-color;
}

p.einrichtung-links > a:before {
    background-image: url(/img/velbert/bg-arrow-content-link.png);
}

.suchergebnis{
  	margin: 48px 0 16px;
	margin: 3rem 0 1rem;
}


.list-group-item{
	display: block;
	background-color: #fff;
}

.inner{
	margin: 0 auto;
	padding: 0 20px;
	padding: 0 1.25rem;
	width: 100%;
	max-width: 1192px;
	max-width: 74.5rem;
}


.contact-block--contacts {
	margin: 0;
	padding: 0;
	list-style: none;
}

.contact-block--contacts--item {
	margin-top: 40px;
	margin-top: 2.5rem;
}

.contact-block--contacts--item:first-child {
	margin-top: 0;
}

.contact-block--contacts--item__vcard{
	display: -webkit-box;
	display: flex;
	flex-basis: 100%;
	padding: 0 10px;
	padding: 0 .625rem;
	margin-top: 0;
	margin-bottom: 24px;
	margin-bottom: 1.5rem;
}



@media (-ms-high-contrast: none){
	.contact-block--contacts--item__vcard {
		display: block;
	}

	::-ms-backdrop .contact-block--contacts--item__vcard{
		display: block;
	}
}






.vcardsportstaette{
	flex-basis: 100%;
	max-width: 100%;
}

.vcard--organisation{
	margin-bottom: 16px;
	margin-bottom: 1rem;
}

.vcard--organisation--name{
	font-size: 21px;
	font-size: 1.31255rem;
	font-weight:500;
	font-weight: bold;
	margin: 0;
	color: #000;
}

.contact-block__bg-grey .vcardsportstaette{
	border-top-color: #e0e0e0;
}

.vcard--definition-list{
	margin: 0;
	flex-wrap: wrap;
	-webkit-box-align: start;
	align-items: flex-start;
}

@media only screen and (min-width:64em){
	.vcardsportstaette{
		padding: 20px;
		padding: 1.25rem;
	}
}



figure{
	display: block;
	margin: 0;
	padding: 0;
}

.image--inner{
	display: block;
	position: relative;
	height: 100%;
}


.image--preview{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
}

.image-preview--img{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	height: auto;
	-webkit-transform: translateY(-50%) translateX(-50%) scale(1.1);
	transform: translateY(-50%) translateX(-50%) scale(1.1);
	-webkit-filter: blur(20px);
	filter: blur(20px);
}

.image--picture{
	position: absolute;
	width: 100%;
	height: 100%;
}

.image-picture img {
	display: block;
	width: 100%;
	height: auto;
	min-height: 100%;
	max-width: 100%;
}





